﻿/* _stacked-species-cards.scss -- */
@import "../base/colors";
@import "../base/typography";
@import "../helpers/mixins";

.species-cards {
    .species-card-col {
        margin-bottom:50px;
    } 
}
a.species-card {
    background-color:white;
    height:100%;
    display:block;
    position:relative;
    @include standard-box-shadow;
    @include transition;
    @media #{$below576}{
        max-width:330px;
        margin-left:auto;
        margin-right:auto;
    }
    .species-image {
        padding-top:129.27756653992395437262357414449%;
        background-size:cover;
        overflow:hidden;
    }
    .species-swoop {
        background-image:url(/images/species-card-swoop.png);
        background-size:cover;
        position:absolute;
        height:100%;
        width:100%;
        top:0;
        left:0;
        opacity:1;
        @include transition($secs: 0.3s);
    }
    h3.species-card-title {
        background-color:$fwc-red;
        padding:12px 20px;
        color:white;
        margin:0;
        text-transform:uppercase;
        font-weight:700;
        display:block;
        word-break: break-word;
        position:absolute;
        bottom:-6px;
        left:-6px;
        width:84%;
        z-index:1;
        @include transition;
        @media #{$above1200} {
            font-size:21px;
        }
        @media #{$between991and1200} {
            width:100%;
            font-size: 17px;
            padding:12px 16px;
        }
        @media #{$below992} {
            font-size:19px;
        }
    }
    &:hover, &:focus {
        h3.species-card-title {
            background-color:$fwc-red-shade;
            text-shadow:1px 1px 1px rgba(0,0,0,.2);
            @include standard-box-shadow($size:3px);
        }
        .species-swoop {
            opacity:0;
        }
    }
    &.featured {
        h3.species-card-title {
            color:$fwc-gray-dark;
            background: #f6cd52;
            background: linear-gradient(rgba(246,206,87,1) 0%, rgba(224,161,50,1) 100%);
        }
    }
    .species-tags {
        pointer-events:none;
        position:absolute;
        left:-6px;
        .species-tag {
            display:block;
            box-sizing:border-box;
            height:31px;
            float:left;
            font-size:9px;
            font-weight:700;
            padding:8px 6px 12px;
            margin-right:2px;
            border-top:0!important;
            border-width:3px;
            border-radius:0 0 6px 6px;
            border-style:solid;
            background-color:white;
            position:relative;
            @include species-tag;
            &:after {
                content:"";
                display:block;
                position:absolute;
                bottom:0px;
                left:0;
                height:6px;
                width:100%;
            }
        }
    }
}
