﻿/* _stacked-alert-banner.scss -- */
//@import "../base/colors";
//@import "../base/typography";
@import "../helpers/mixins";

.alert-banner {
    background-color: $flmtph-mediumgold;
    border-color: $flmtph-darkgold;
    padding: 30px 0;
    position: relative;
    @include shaded-strips($height:4px);
    @include link-icons;

    .alert-icon {
        margin-bottom: 30px;

        .svg-inline--fa {
            font-size: 120px;
            color: white;
            opacity: .95;
            text-align: center;
            width: 100%;

            @media #{$below992} {
                font-size: 100px;
            }
            @media #{$below768} {
                font-size: 66px;
            }
        }
    }
    .alert-text {
        font-family: $alt-font-family-san-serif;
        font-weight: $light;
        h2 {
            font-family: $alt-font-family-san-serif;
            font-weight: $bold;
            text-transform:uppercase;
        }
    }
}
