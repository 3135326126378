﻿/* _stacked-accordion.scss -- */
@import "../base/colors";
@import "../base/typography";
@import "../helpers/mixins";

.fwc-accordion {
    @include link-icons;
    .fwc-accordion-header {
        background-color:$fwc-teal;
        color:white;
        border-top:1px solid $fwc-teal-shade;
        border-bottom:1px solid $fwc-teal-shade;
        margin-bottom:-1px;
        a {
            color:white;
            font-size:20px;
            font-weight:700;
            display:block;
            &.accordion-header-text {
                padding:12px 0 12px 30px;
                width:95%;
            }
            &.accordion-icons {
                padding:13px 0;
                width:5%;
                min-width:57px;
                .svg-inline--fa {
                    font-size:28px;
                    margin:auto;
                }
                .fwc-minus {
                    display:block;
                }
                .fwc-plus {
                    display:none;
                }
                &.collapsed {
                    .fwc-minus {
                        display:none;
                    }
                    .fwc-plus {
                        display:block;
                    }
                }
            }
        }
    }
    .fwc-accordion-content {
        background-color:#e7f1f2;
        padding:15px 30px;
        .col-md {
            @include word-break;
        }
        /* Edge */
        @supports (-ms-ime-align:auto) {
            .col-md {
                word-break: break-all;
                text-align:justify;
            }
        }
        .multi-lists, .single-list {
            padding:0;
        }
        ul.stacked-multi-list {
            li {
                border-color:white;
            }
        }
        .container {
            padding-right:0;
            padding-left:0;
        }
    }
    .expand-links {
        padding: 4px 16px 0;
        background-color:$fwc-teal-shade;
        color:white;
        width:330px;
        justify-content:space-around;
        align-items:center;
        float:right;
        @include flex;
        a {
            color:white;
            font-size:16px;
        }
    }
}