﻿/* _colors.scss -- Color Variables */

$fwc-blue: #286da8;
$fwc-teal: #348185;
$fwc-green: #488267;
$fwc-red: #b7473c;
$fwc-brown: #5e3a34;
$fwc-blue-shade: #1e5292;
$fwc-teal-shade: #2b6f75;
$fwc-green-shade: #568871;
$fwc-red-shade: #a5352d;
$fwc-brown-shade: #4b3a31;
$fwc-light-tan: #f8f4ea;
$fwc-medium-tan: #ddd3b9;
$fwc-dark-tan: #bab199;
$fwc-gray-light: #58595b;
$fwc-gray-medium: #494b4c;
$fwc-gray-dark: #404040;
$fwc-warm-white: #fffffc;
$fwc-not-quite-black: #020101;
$fwc-header-top-border: #e7dec7;
$fwc-logo-title: #5e3a34;
$fwc-mission: #f1f7f6;
$fwc-mission-sm: #e2eeee;
$fwc-alert-banner: #f7eba7;
$fwc-top-nav-border: #65696c;

//FLMTPH Colors
$flmtph-deepbrown: #5e3a34;
$flmtph-darkgold: #df9941;
$flmtph-mediumgold: #e2b460;
$flmtph-lightgold: #e4ca7e;
$flmtph-darkbluegray: #5c6c76;
$flmtph-mediumbluegray: #74889a;
$flmtph-lightbluegray: #92aac1;

//species tags
$tag-imperiled: #2E7D67;
$tag-nonnative: #8C5AB5;
$tag-invasive: #D02F58;
$tag-native: #566ac1;
$tag-managed: #3D7889;

//social media
$fb-color: #3a5a99;
$googleplus-color: #dc4b3e;
$insta-color: #517ea3;
$pin-color: #bd081c;
$snapchat-color: #ffea00;
$twitter-color: #55acee;
$yt-color: #e52d27;
$flickr-color: #FC329B;
$vim-color: #4EBBFF;
$linkedin-color: #0077B5;
$periscope-color: #3aa4c6;
