﻿/* _stacked-tables.scss -- */
@import "../base/colors";
@import "../base/typography";
@import "../helpers/mixins";

.fwc-table, .report-table {
    table {
        width:100%;
        border-collapse:collapse;
        @include link-icons;
        th {
            background-color:$fwc-gray-light;
            border-right:1px solid $fwc-medium-tan;
            color:white;
            text-align:center;
            padding:10px 8px;
            font-weight:700;
            &:last-of-type {
                border-right:0;
            }
        }
        tr {
            background-color:#fbf9f3;
            td:last-of-type {
                border-right:0;
            }
        }
        tr:nth-of-type(odd) {
            background-color:#f2ead7;
        }
        td {
            border-bottom:1px solid $fwc-medium-tan;
            border-right:1px solid $fwc-medium-tan;
            padding:10px 8px;
            text-align:left;
            @include word-break;
        }
        p {
            margin:0;
        }
        &.blue {
            th {
                background-color:$fwc-blue;
            }
            tr {
                background-color:#fbf9f3;
                &:nth-of-type(odd) {
                    background-color:#e9f1f7;
                }
            }
        }
        &.green {
            th {
                background-color:$fwc-green;
            }
            tr {
                background-color:#fbf9f3;
                &:nth-of-type(odd) {
                    background-color:#e3f2eb;
                }
            }
        }
        &.alternating-blue {
            tr {
                background-color:white;
                &:nth-of-type(odd) {
                    background-color:#e9f1f7;
                }
            }
            td, th {
                border-color:#c8e1f4;
            }
        }
        &.alternating-green {
            tr {
                background-color:white;
                &:nth-of-type(odd) {
                    background-color:#e3f2eb;
                }
            }
            td, th {
                border-color:#cee4da;
            }
        }
    }
}


/*RESPONSIVE TABLES*/
@media only screen and (max-width: 768px), (min-device-width: 768px) and (max-device-width: 1024px) {
    .fwc-table {
        table {
            /* Force table to not be like tables anymore */
            table, thead, tbody, th, td, tr {
                display: block;
            }

            /* Hide table headers (but not display: none;, for accessibility) */
            thead tr {
                position: absolute;
                top: -9999px;
                left: -9999px;
            }

            tr {
                border: 1px solid $fwc-medium-tan;
            }

            td {
                /* Behave  like a "row" */
                position: relative;
                /*padding-left: 50%;*/
                border-right:0;
                font-size:19px;
                box-sizing:initial;
                min-height:49px;
            }

            td:before {
                /* Now like a table header */
                position: relative;
                /* Top/left values mimic padding */
                width: 100%;
                padding-right: 10px;
                content: attr(data-title);
                font-size:19px;
                font-weight:700;
                display:block;
                margin-bottom:5px;
            }
        }
    }
}