﻿/* _stacked-tabs.scss -- */
@import "../base/colors";
@import "../base/typography";
@import "../helpers/mixins";

.fwc-tabs {
    ul.nav.nav-tabs {
        position: relative;
        margin: 0;
        border: 0;
        padding: 15px 7px 0;
        background-color: $fwc-teal-shade;

        li {
            list-style-type: none;
            padding: 0 8px;
            margin: 0;

            a.nav-link {
                position: relative;
                border-radius: 0;
                border: 0;
                background-color: $fwc-teal;
                color: white;
                font-size: 18px;
                font-weight: 600;
                z-index: 1;

                &.active {
                    background-color: $fwc-light-tan;
                    color: $fwc-not-quite-black;

                    &:after {
                        display: none;
                    }
                }

                &:after {
                    @extend %nav-shadow;
                }
            }
        }

        &:after {
            @extend %nav-shadow;
        }
    }

    .tab-content {
        padding: 40px 40px 20px;
        background-color: $fwc-light-tan;
        border-bottom: 6px solid #f2ead7;

        .col-md {
            @include word-break;
        }
        /* Edge */
        @supports (-ms-ime-align:auto) {
            .col-md {
                word-break: break-all;
                text-align: justify;
            }
        }

        @media #{$below768} {
            padding: 40px 15px 20px;
        }
    }
}
%nav-shadow {
    display:block;
    content:"";
    height:6px;
    width:100%;
    background-color:rgba(0,0,0,.14);
    position:absolute;
    bottom:0;
    left:0;
    z-index:0;
}
