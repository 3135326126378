﻿/* _stacked-caption-image-grid.scss -- */
@import "../base/colors";
@import "../base/typography";
@import "../helpers/mixins";

.caption-image-list {
    .container {
        @media #{$below576}{
            max-width:360px;
            margin-left:auto;
            margin-right:auto;
        }
        .row {
            .col-sm:nth-child(4n) {
                clear:right;
            }
        }
    }
    h2.section-title {
        margin-top:30px;
    }
}
.caption-image-row {
    margin-left:-5px;
    margin-right:-5px
}
a.caption-link {
    position:relative;
    box-sizing:border-box;
    @include transition;
    .svg-inline--fa {
        font-size: 14px;
        right: 12px;
        top: 12px;
        position: absolute;
        display: block;
        color:white;
    }
    &:focus, &:hover {
        border:6px solid $fwc-gray-dark;
        @include standard-box-shadow;
        .caption {
            background-color:$fwc-gray-dark;
        }
    }
}
.caption-link {
    display: block;
    position: relative;
    margin: 5px;
}
img.caption-image {
    display:block;
    max-width:100%;
    width:100%;
}

/* below implements external/document/image icons in the text area of cards with caption | kylencobb 10/15/18 */
a.caption-link.external .caption span {
    background-repeat: no-repeat;
    padding-left: 20px;
    background-image: url('/images/external-white.png');
    background-position: left 50%;
}
a.caption-link {
    &[href$=".pdf"] {
        .caption span {
            background-repeat: no-repeat;
            padding-left: 20px;
            background-image: url('/images/pdf-white.png');
            background-position: left 50%;
        }
    }

    &[href$=".png"], &[href$=".jpg"], &[href$=".svg"], &[href$=".jpeg"], &[href$=".gif"] {
        .caption span {
            background-repeat: no-repeat;
            padding-left: 20px;
            background-image: url('/images/pic-white.png');
            background-position: left 50%;
        }
    }

    &[href$=".doc"], &[href$=".docx"], &[href$=".xls"], &[href$=".xlsx"], &[href$=".txt"] {
        .caption span {
            background-repeat: no-repeat;
            padding-left: 20px;
            background-image: url('/images/doc-white.png');
            background-position: left 50%;
        }
    }
}
