﻿/* _stacked-link-lists.scss -- */
@import "../base/colors";
@import "../base/typography";
@import "../helpers/mixins";

.multi-lists, .single-list {
    padding:30px 0;
    position:relative;
    @include link-icons;
    ul.stacked-multi-list {
        font-size:19px;
        font-weight:600;
        margin:0 0 1rem;
        padding-left:0;
        li {
            list-style-type:none;
            padding:8px;
            border-top:1px solid;
            border-color:$fwc-medium-tan;
        }
        &.single {
            @media #{$above768} {
                @include flex;
                justify-content:space-between;
                flex-wrap:wrap;
                li {
                    width:31.5%;
                }
            }
            &.two-column {
                li {
                    @media #{$above768} {
                        width:48.5%;
                    }
                }
            }
        }
    }
    &.brown {
        ul.stacked-multi-list {
            li {
                border-color:$fwc-light-tan;
            }
        }
    }
    &.green {
        ul.stacked-multi-list {
            li {
                border-color:$fwc-green-shade;
                a {
                    color:white;
                }
            }
        }
        h2 {
            color:white;
        }
    }
    &.teal {
        ul.stacked-multi-list {
            li {
                border-color:$fwc-teal-shade;
                a {
                    color:white;
                }
            }
        }
        h2 {
            color:white;
        }
    }
    h2 {
        margin-bottom:30px;
    }
    .svg-inline--fa {
        display:none;
    }
}
.multi-lists.card-layout {
    .list-card {
        background-color:white;
        @include standard-box-shadow;
    }
    h2 {
        font-size:20px;
        background-color:$fwc-blue;
        color:white;
        padding:16px 12px;
        line-height:1.3;
        margin-bottom:0;
        .svg-inline--fa {
            display:inline-block;
            font-size:36px;
            margin-right:10px;
            margin-bottom:-6px;
        }
    }
    ul.stacked-multi-list {
        li {
            padding:12px 8px;
        }
    }
    &.teal, &.green {
        h2 {
            background-color:$fwc-gray-medium;
        }
        ul.stacked-multi-list {
            li {
                border-color:$fwc-light-tan;
                a {
                    color:$fwc-blue;
                }
            }
        }
    }
}
.col-list-image {
    @media #{$above768} {
        float:left;
        width:31.5%;
        margin-right:1.833%;
        margin-bottom:0;
    }
    max-width:100%;
    margin-bottom:1rem;
}
