﻿/* _typography.scss -- */
//@import "../helpers/mediaqueries";
@import "../helpers/mixins";
//@import "../base/colors";
//@import "../base/fonts";

// Headings
h1, h2, h3, h4, h5, h6 {
    font-family: $base-font-family;
    font-weight: $bold;
    line-height: $base-line-height - .4;
    margin:0;
    color:$flmtph-deepbrown;
    a {
        color:$fwc-blue;
    }
}
h1 {
    font-size:36px;
    font-weight:$medium;
}
h2 {
    font-size:23px;
    margin-bottom:1rem;
}
h3 {
    font-size:21px;
    margin-bottom:.9rem;
}
h4 {
    font-size:21px;
    font-weight:$semi-bold;
    margin-bottom:.8rem;
}
h5 {
    font-size:20px;
    font-weight:$semi-bold;
    margin-bottom:.7rem;
}
h6 {
    font-size:20px;
    font-weight:$light;
    margin-bottom:.7rem;
}
p {
    margin:1rem 0 1.6rem;
}
h2.section-title {
    padding-bottom:8px;
    border-bottom:1px solid $flmtph-mediumbluegray;
    margin-bottom:30px;
}
ul, ol {
    margin:15px 0 15px 0;
    list-style-position:inside;
    padding-left:30px;
    li {
        padding:8px;
    }
    ul, ol {
        margin:0;
    }
}
ul {
    > li {
        list-style-type:disc;
    }
    ul li {
        list-style-type:circle;
    }
    ul ul li {
        list-style-type:disc;
    }
    ul ul ul li {
        list-style-type:circle;
    }
    ul ul ul ul li {
        list-style-type:disc;
    }
    ol li {
        list-style-type:decimal;
    }
    ol ol li {
        list-style-type:upper-alpha;
    }
    ol ol ol li {
        list-style-type:decimal;
    }
    ol ol ol ol li {
        list-style-type:lower-alpha;
    }
    ol ol ol ol ol li {
        list-style-type:lower-roman;
    }
}
ol {
    > li {
        list-style-type:decimal;
    }
    ol li {
        list-style-type:upper-alpha;
    }
    ol ol li {
        list-style-type:decimal;
    }
    ol ol ol li {
        list-style-type:lower-alpha;
    }
    ol ol ol ol li {
        list-style-type:lower-roman;
    }
    ul li {
        list-style-type:disc;
    }
    ul ul li {
        list-style-type:circle;
    }
    ul ul ul li {
        list-style-type:disc;
    }
    ul ul ul ul li {
        list-style-type:circle;
    }
}
.list-style-none {
    list-style:none!important;
}
blockquote {
    position:relative;
    padding:30px;
    border-radius:8px;
    background-color:#d9d9d9;
    margin-bottom:30px;
    color:#6a6a6a;
    font-family: $alt-font-family-serif;
    line-height:$base-line-height;
    font-size:1.4rem;
    @include standard-text-shadow;
    p {
        color:#6a6a6a;
        font-family: $alt-font-family-serif;
        line-height:$base-line-height;
        font-size:1.4rem;
    @include standard-text-shadow;
    }
    &:after {
        position: absolute;
        content: "";
        display: block;
        bottom: -22px;
        left: 24px;
        width: 0;
        height: 0;
        border-top: 40px solid #d9d9d9;
        border-right: 40px solid transparent;
        border-left: 40px solid transparent;
    }
}
cite {
    font-weight:$semi-bold;
}
em {
    font-style:italic;
}
strong {
    font-weight:$semi-bold;
}
.rte {
    @include link-icons;
}
.rte-img {
    display: inline-block;
    max-width: 100%;
    margin: 0 0 1.6rem;

    img {
        width: 100%;
    }

    &.left {
        margin: .5rem 3rem 1.6rem 0;
        max-width: 360px !important;
        width: 100%;
        float: left;
    }

    &.right {
        margin: .5rem 0 1.6rem 3rem;
        max-width: 360px !important;
        width: 100%;
        float: right;
    }

    &.left, &.right {
        @media #{$below768} {
            max-width: 100%;
        }
    }

    &.left, &.right {
        @media #{$below590} {
            max-width: 100%;
            float: none !important;
            margin: inherit auto;
        }
    }
}
