﻿/* _fonts.scss -- Font Variables */

// Font weights
$thin: 100;
$extra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$black: 900;

// Base Font
$base-font-family: 'Raleway', sans-serif;
$base-font-weight: 400;
$base-font-size: 20px;
$base-line-height: 1.5;

//Alternate Font
$alt-font-family-serif: 'Karma', serif;
$alt-font-family-san-serif: 'Karma', san-serif;
$logo-title-font-family: 'Myriad Pro', Arial, sans-serif;