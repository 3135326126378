﻿// _mixins.scss --
@import "../base/colors";
@import "../base/fonts";
@import "../base/variables";
@import "../helpers/mediaqueries";

@mixin transition($secs: 0.1s, $ease: ease-in) {
    -webkit-transition: all $secs $ease;
    -moz-transition: all $secs $ease;
    -o-transition: all $secs $ease;
}
@mixin flex() {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin standard-box-shadow($size:1px) {
    -webkit-box-shadow: 1px 1px $size 0 rgba(0,0,0,0.18);
    -moz-box-shadow: 1px 1px $size 0 rgba(0,0,0,0.18);
    box-shadow: 1px 1px $size 0 rgba(0,0,0,0.18);
}

@mixin standard-text-shadow($color: rgba(255,255,255,0.6)) {
    text-shadow: 1px 1px 1px $color;
}

@mixin standard-border($sides: all, $color: $fwc-medium-tan, $size: 1px) {
    @if $sides!=all {
        @each $side in $sides {
            border-#{$side}: $size solid $color;
        }
    }
    @else {
        border: $size solid $color;
    }
}

@mixin shaded-strips($color:rgba(78,78,78,.35), $height:2px, $z:1) {
    &:before, &:after {
        content:"";
        display:block;
        position:absolute;
        height:$height;
        width:100%;
        background-color:$color;
        z-index:$z;
    }

    &:before {
        top:0;
    }

    &:after {
        bottom:0;
    }
}

//moved parts to theme.scss 5/10/2021 JK
@mixin caption($position: absolute) {
    position:$position;
    bottom:0;
    width:100%;
    padding:12px 10px;
    text-align:center;
    font-family: $base-font-family;
    font-size:18px;
    font-weight: $regular;
    box-sizing:border-box;
    -moz-box-sizing: border-box;
    @include transition;

    @media #{$below1200} {
        font-size: calc(10.8px + 4 * ((100vw - 320px) / 800));
        padding:6px 10px;
    }

    @media #{$below576} {
        font-size:16px;
    }
}

@mixin word-break() {
    word-wrap: break-word;
    overflow-wrap: break-word;
    /*word-break: break-word;*/
    -ms-word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    @media #{$below768} {
        word-break: break-all;
    }
}

// FILTERS
// grayscale      ex: filter: grayscale(100%);
// sepia          ex: filter: sepia(100%);
// saturate       ex: filter: saturate(0%);
// hue-rotate     ex: filter: hue-rotate(45deg);
// invert         ex: filter: invert(100%);
// brightness     ex: filter: brightness(15%);
// contrast       ex: filter: contrast(200%);
// blur           ex: filter: blur(2px);

@mixin filter($filter-type,$filter-amount) { 
  -webkit-filter: $filter-type+unquote('(#{$filter-amount})');
  -moz-filter: $filter-type+unquote('(#{$filter-amount})');
  -ms-filter: $filter-type+unquote('(#{$filter-amount})');
  -o-filter: $filter-type+unquote('(#{$filter-amount})');
  filter: $filter-type+unquote('(#{$filter-amount})');
}
@mixin angled-border() {
    height:1px;
    width:100%;
    position:absolute;
    left:0;
    background-color:$fwc-medium-tan;
    &:before, &:after {
        display:block;
        position:absolute;
        content:"";
        width:19px;
        height:19px;
        background-image:url('/images/corner-tan.png');
    }
    &.top {
        top:0;
        &:before {
            top:0;
            left:0;
            transform: rotate(180deg);
        }
        &:after {
            top:0;
            right:0;
            transform: rotate(-90deg);
        }
    }
    &.bottom {
        bottom:0;
        &:before {
            bottom:0;
            left:0;
            transform: rotate(90deg);
        }
        &:after {
            bottom:0;
            right:0;
        }
    }
}

@mixin standard-tag($color:$fwc-gray-dark) {
    display:inline-block;
    vertical-align: top;
    box-sizing:border-box;
    height:34px;
    font-size:14px;
    font-weight:$bold;
    padding: 5px 10px 3px 28px;
    margin-right:5px;
    border-width:3px;
    border-radius:10px;
    border-style:solid;
    background-color:white;
    position:relative;
    overflow:hidden;
    line-height:1.4;
    white-space:nowrap;
    border-color:$color;
    color:$color;
    &:after {
        content:"";
        display:block;
        position:absolute;
        bottom:0px;
        left:0;
        height:100%;
        width:20px;
        background-color:$color;
        @include transition;
    }
    &.active, &:focus, &:hover {
        color:$fwc-blue;
        &:after {
            background-color:$fwc-blue;
        }
    }
    a {
        color:$color;
    }
}
@mixin list-override($margin:0) {
    margin:$margin;
    list-style-position:unset;
    padding:0;
    li {
        list-style-type:none;
        padding:0;
    }
}

@mixin species-tag() {
    &.imperiled {
        border-color:$tag-imperiled;
        color:$tag-imperiled;
        &:after {
            background-color:$tag-imperiled;
        }
    }
    &.nonnative {
        border-color:$tag-nonnative;
        color:$tag-nonnative;
        &:after {
            background-color:$tag-nonnative;
        }
    }
    &.invasive {
        border-color:$tag-invasive;
        color:$tag-invasive;
        &:after {
            background-color:$tag-invasive;
        }
    }
    &.native {
        border-color:$tag-native;
        color:$tag-native;
        &:after {
            background-color:$tag-native;
        }
    }
    &.managed {
        border-color:$tag-managed;
        color:$tag-managed;
        &:after {
            background-color:$tag-managed;
        }
    }
}

@mixin fwc-button($color, $active-color) {
    //moved from _button.scss | Andrew 2/4/20
    background-color: $color;

    &:hover, &:focus, &.is-active {
        background-color: $active-color;
    }

    @extend .base-fwc-button;
}

@mixin link-icons() {
    // Updated to use link-background-icon mixin | Andrew 2/4/20
    // ADD ICONS TO TEXT LINK TYPES
    a:not(.fwc-button), button:not(.fwc-button), input:not(.fwc-button) {
        background-repeat:no-repeat;
        @include link-background-image();
    }
    
    &.teal, &.green, &.red, .teal, .green, .red {
        &:not(.card-layout) {
            background-repeat:no-repeat;
            // SWITCH ICON COLOR TO WHITE ON COLORED BG
            a:not(.fwc-button), button:not(.fwc-button), input:not(.fwc-button) {
                @include link-background-image($background-color-default: false);
            }

            p {
                font-weight: $medium;
            }
        }
    }
}

@mixin link-background-image-style($add-no-repeat: false,$padding-left: 20px, $background-position-LR: left, $background-position-TB: 50%) {
    // Added for changing styling of link icons | Andrew 2/4/20
    @if $padding-left != null {
        padding-left: $padding-left;
    }

    @if $add-no-repeat == true {
        background-repeat: no-repeat;
    }

    background-position: $background-position-LR $background-position-TB;
}

@mixin link-background-image($background-color-default: true, $selector: null, $add-no-repeat: false,$padding-left: 20px, $background-position: left) {
    // use sass selectors like > .caption, or &[href$=".pdf"] for the selector variable
    // Added for creating link icons | Andrew 2/4/20
    @each $name, $type in $link-icon-types {

        #{$type} {

            $iconfile: '/images/'+$name+'-white.png';
          
            @if $background-color-default == true {
                $iconfile: '/images/'+$name+'.png';
                }

            @if $selector != null {
                #{$selector} {
                    @include link-background-image-style($add-no-repeat, $padding-left, $background-position);
                    background-image: url(#{$iconfile});
                }
            }
            @else {
                @include link-background-image-style($add-no-repeat, $padding-left, $background-position);
                background-image: url(#{$iconfile});
            }
        }
    }
}

@mixin colored-row-green() {
    @include shaded-strips($color:$fwc-green-shade, $height:3px);
    background-color:$fwc-green;
    position:relative;

    h2, h3, h4, h5, p {
        color:white;

        a {
            color:white;
        }
    }

    a {
        color:white;
    }
}

@mixin colored-row-teal() {
    @include shaded-strips($color:$fwc-teal-shade, $height:3px);
    background-color:$fwc-teal;
    position:relative;

    h2, h3, h4, h5, p {
        color:white;

        a {
            color:white;
        }
    }

    a {
        color:white;
    }
}

@mixin colored-row-brown() {
    @include shaded-strips($color:$fwc-dark-tan, $height:3px);
    background-color:$fwc-medium-tan;
    position:relative;
}

@mixin colored-row-white() {
    @include shaded-strips($color:$fwc-medium-tan, $height:3px);
    background-color:white;
    position:relative;
    }

    @mixin title-swoosh() {
        //Added a for a single implementation of the title swoosh | Andrew 2/7/20
        @include standard-border((bottom, top));
        @include link-background-image-style(true, null, right, top);
        // background-image: url('/images/title-swoosh.png');
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAaAAAABGCAMAAAC9grBAAAAAdVBMVEU6jJH///86jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJE6jJGwXIGkAAAAJ3RSTlMAAAECAwQFBgcICQoLDA0ODxAREhMUFRYXGBkaGxwdHh8gISIjJCX6V6S5AAAC40lEQVR4XuzZWW7DMAwE0FSy5EXeGTuOs3W//xG7/PBbMugS6MwVHgQNycOTyhykkhV1P1JU5svr59/lfwH50FFk5us+PAAyvuopNqfr2x4KALKubId4ntsuPACyed2PFJvl9r6HAIB83VN8lrskD4D47QyUkPP9QxoAQMb6qk3iWR/yPADKyqanhBzXZ3EAANmiHSkl0+VFGABAJisa1tE1lQLI8C5H39gDIOtDx7VAWa8GkA/cCpQVNwDZPAyUmkm0uAHIZHnoRuJoOicA6HccZR5dzQBAjpcFKTlLNgMAOb7waNsZAMjmYZMOzRI7AwBxKxhG2pBF7OsBEC+pFU49AHIVN2plUw+AjK8H2piT0D4UQFnRsI6uWg0g68qmH2ljplWgtwHIWFewjrLeBiDDl+stOYr0NgC5ii/XmmZSAH21czfLDcIwEIAzEv8QSmqgSdpD3/8pM5z24EsYRGYPq1f4RmYt20RENgSDHWsbK9CFq6xEZGPb9AgI5wcBwSB20yMgK+punGJ0FgQDAUXp9JiykU0MBOTNgCkbWaoWUNEiT5M1j4AMLxBimidw3CagEq3D1jwCym5L8cQ2AXmF+XRM3dE8AiLZ7KDW5//B5hEQDg+Gr1sKrUfMSYKArKg6LGxB9YNRtYAOt04wTlpCQrWAzKsuYDwdPQsVEB69pfBafwMOEgTkNf5fFLq0Hb+eIyDDiTXP0iag/BobVWoTUP4DFqZpjoAMT3mjaz56/UNAZYdEoExNBlS0IwC4PjwCyn++QjMJFZBncU3DnHggsrgGHcQCAe3HmdKZNT92xAIB5U+s2XQEhBfW6eS6/+0PbQIyc1zzINEREHDKBjhMOgIyL6p2AA7TdlRAXtbdFZc8zu2dfdtRAXnV9NcJOEoFREC+rWrTdyLUEZDX/TjdPoMz79MRkNdZHKDYjQrIzMoWY8/za3m+qSOgi21ZLcNhmIKqthzdDuMncdL6/gmC6gX3hwFGSoo8kAAAAABJRU5ErkJggg==');
        background-size: 40% 100%;
    }

    @mixin play-button() {
        //Added a for a single implementation of the play button | Andrew 2/7/20
        @include link-background-image-style(true, null, center, center);
        background-image: url(/images/play-icon.png);
        background-color: rgba(0,0,0,.5);
    }

    @mixin block-absolute-element($height: null, $width: null) {
        display: block;
        content: "";
        position: absolute;

        @if $height != null {
            height: $height;
        }

        @if $width != null {
            width: $width;
        }
    }

    // Added from joelstien to allow horizontal list Andrew 3/9/20 Start
    // Standard horizontal list, using word-spacing trick to remove whitespace
    // between inline-block elements.

    @mixin horizontal-list {
        padding: 0;
        text-align: center;
        word-spacing: -1em;
        display: table;
        width: 100%;

        li {
            display: inline-block;
            word-spacing: 0;
        }
    }

    // Horizontal list separated.

@mixin horizontal-list-separated($color: $fwc-medium-tan, $size: 3px) {
    @include horizontal-list;

    li:not(:last-of-type) {
        border-right: $size solid;
        border-color: $color;
    }
}

    // Horizontal list with padded links.

    @mixin horizontal-list-padded($vertical, $horizontal) {
        @include horizontal-list;

        li {
            padding: $vertical 0;

            a {
                padding: $vertical $horizontal;
            }
        }
    }
    // Added from joelstien to allow horizontal list Andrew 3/9/20 End