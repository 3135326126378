﻿/* _stacked-blockquote.scss -- */
//@import "../base/colors";
//@import "../base/typography";
@import "../helpers/mixins";

.blockquote {
    @include link-icons;
    .blockquote-image {
        padding:20px 0 20px 30px;
        img {
            max-width:100%;
        }
    }
    .blockquote-text {
        padding:20px 20px 0 0;
        font-family: $alt-font-family-san-serif;
        font-weight: $light;
        h2 {
            font-family: $alt-font-family-san-serif;
            font-weight: $bold;
            text-transform:uppercase;
        }
    }
    .angled-border {
        @include angled-border;
    }
    .text-center p, text-center h2 {
        padding: 0 10%;
    }
}
