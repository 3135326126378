﻿/* _stacked-infographic.scss -- */
//@import "../base/colors";
//@import "../base/typography";
@import "../helpers/mixins";

.infographic {
    &.teal, &.green, &.brown {
        padding:30px 0;
        .section-title {
            border-color:white;
        }
    }
}

.infographic-image {
    width: 100%;
}

figure.infographic-figure {
    margin: auto;
}

a.infographic-image-no-caption {
    display: table;
    margin: auto;
}

section.infographic {
    padding: 30px 0;
}

/*Have to target FireFox to fix issue*/
@-moz-document url-prefix() {
    .infographic-image {
        width: auto;
    }
}