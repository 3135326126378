﻿/* _stacked-cta-panel.scss -- */
@import "../base/colors";
@import "../base/typography";
@import "../helpers/mixins";

.cta-panel {
    .stacked-wrap {
        background-color:white;
        position:relative;
        height:197px;
        @include shaded-strips($height:2px, $z:2);
        @media #{$below768} {
            height:auto;
        }
    }
    h2 {
        text-transform:uppercase;
    }
    p {
        max-height:60px;
        overflow:hidden;
        font-family: 'Raleway', sans-serif;
        font-weight:400;
        @media #{$below768} {
            max-height:unset;
        }
    }
    .cta-content {
        @include vertical-align;
        @media #{$below768} {
            text-align:center;
        }
    }
    .col-lg-5 {
        background-color:white;
    }
    .cta-image {
        background-position:right top;
        background-repeat:no-repeat;
        height:100%;
        position:absolute;
        top:0;
        right:0;
        width:80%;
        @media #{$below768} {
            display:none;
        }
    }
    .cta-right {
        height:100%;
        position:relative;
        @media #{$below768} {
            text-align:center;
        }
        .fwc-button {
            position:absolute;
            right:0;
            bottom:20px;
            @media #{$below768} {
                position:relative;
                margin-top:20px;
            }
        }
        &:before {
            background-image:url(/images/cta-swoop.png);
            content:"";
            display:block;
            position:absolute;
            left:0;
            top:0;
            height:100%;
            width:184px;
            z-index:1;
            @media #{$below768} {
                display:none;
            }
        }
    }
    .container, .row {
        height:100%;
        z-index:1;
    }
}
