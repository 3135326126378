﻿/* _flickr.scss -- */
//@import "../base/colors";
//@import "../base/typography";
@import "../helpers/mixins";

.flickrwrap {
    position: relative;
    overflow: hidden;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}