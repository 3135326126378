﻿/* _stacked-alternating-image-list.scss -- */
@import "../base/colors";
@import "../base/typography";
@import "../helpers/mixins";

.alternating-image-list {
    @include link-icons;
    .stacked-wrap {
        padding:60px 0;
        position:relative;
        @media #{$below576} {
            padding:60px 0;
        }
    }
    .alternating-image {
        img {
            max-width:100%;
            box-shadow: 
                -5px 5px 0 0 $fwc-light-tan,
                -10px 10px 0 0 $fwc-medium-tan;
        }
        figcaption {
            margin-top:15px;
        }
    }
    .alternating-content {
        padding-left:67px;
        @media #{$below576} {
            padding-left:0;
        }
    }
    &.green, &.teal {
        .alternating-content {
            p a {
                font-weight:900;
                letter-spacing:1px;
                padding:2px 2px 2px 20px;
                background-image: url('/images/link.png');
                background-repeat:no-repeat;
                background-position: 2px 50%;
                margin: 0 10px;
                &:hover, &:focus {
                    background-color:white;
                    background-image: url('/images/link-blue.png');
                    border-radius:16px;
                    color:$fwc-blue;
                }
            }
        }
    }
    ul.link-list {
        font-size:19px;
        font-weight:600;
        margin:0 0 1rem;
        padding-left:0;
        li {
            list-style-type:none;
            padding:8px;
            border-top:1px solid;
            border-color:$fwc-medium-tan;
            &:last-child {
                border-bottom:1px solid;
                border-color:$fwc-medium-tan;
            }
        }
    }
    &.image-right {
        .alternating-content {
            padding-left:0;
            padding-right:67px;
            @media #{$below576} {
                padding-right:0;
            }
        }
        .alternating-image {
            img {
                @media #{$below576} {
                    margin-bottom:0;
                    margin-top:30px;
                }
            }
        }
    }
    &.brown .stacked-wrap {
        @include colored-row-brown;
        .alternating-image {
            img {
                box-shadow: 
                    -5px 5px 0 0 $fwc-medium-tan,
                    -10px 10px 0 0 $fwc-dark-tan;
            }
        }
        ul.link-list {
            li {
                border-color:$fwc-light-tan;
            }
        }
    }
    &.teal .stacked-wrap {
        @include colored-row-teal;
        .alternating-image {
            img {
                box-shadow: 
                    -5px 5px 0 0 $fwc-teal,
                    -10px 10px 0 0 $fwc-teal-shade;
            }
        }
        .alternating-content {
            color:white;
            h2, a {
                color:white;
            }
        }
        ul.link-list {
            li {
                border-color:$fwc-teal-shade;
            }
        }
    }
    &.green .stacked-wrap {
        @include colored-row-green;
        .alternating-image {
            img {
                box-shadow: 
                    -5px 5px 0 0 $fwc-green,
                    -10px 10px 0 0 $fwc-green-shade;
            }
        }
        .alternating-content {
            color:white;
            h2, a {
                color:white;
            }
        }
        ul.link-list {
            li {
                border-color:$fwc-green-shade;
            }
        }
    }
}