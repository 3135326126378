﻿/* news.scss -- */
@import "base/colors";
@import "base/typography";
@import "helpers/mixins";


/*NEWS DETAIL PAGE*/
.news-data {
    margin: .5rem 3rem 1.6rem 0;
    max-width: 360px;
    float: left;
    @media #{$below768} {
        float:none;
    }
}
img.news-img {
    max-width:100%;
    margin-bottom:30px;
    box-shadow: 
        -5px 5px 0 0 $fwc-light-tan,
        -10px 10px 0 0 $fwc-medium-tan;
}
.news-data-item {
    display:block;
    padding: 8px;
    border-top: 1px solid;
    border-color: white;
    &:first-child {
        border-top:0;
    }
}
.news-data-items {
    background-color:$fwc-medium-tan;
    padding:15px;
}
.tag {
    @include standard-tag;
    &.show-all {
        @include standard-tag($color:$fwc-red);
        margin-bottom:0;
    }
}

/*NEWS LIST PAGE*/
.news-sidebar {
    background-color: $fwc-medium-tan;
    padding: 15px;
    margin-bottom:30px;
}
.sidebar-section {
    padding:12px 0;
    border-top:1px solid white;
    border-bottom:1px solid white;
}
.news-tags {
    margin:15px 0;
    .tag {
        margin-bottom:5px;
    }
}
.news-articles-summary {
    border-top:1px solid $fwc-medium-tan;
    border-bottom:1px solid $fwc-medium-tan;
    margin-bottom:15px;
    color:#6a6a6a;
    font-family: 'Karma', serif;
    line-height:1.5;
    font-size:1.4rem;
}
ul.news-articles-list {
    @include list-override($margin:0 0 15px);
    li {
        padding:20px 0;
        border-top:1px solid $fwc-medium-tan;
        border-bottom:1px solid $fwc-medium-tan;
        margin-bottom:-1px;
        img.news-img {
            float:left;
            margin-right:15px;
        }
    }
    .news-list-date {
        font-size:16px;
        font-weight:600;
    }
}
p.showing {
    margin: 1rem 0 .8rem;
}
span.tag-showing {
    font-weight:700;
}