﻿/* _stacked-anchor-link-lists.scss -- */
@import "../helpers/mixins";

.row-lists {
    padding: 0;
    position: relative;
    @include link-icons;

    ul.anchor-list {
        @include horizontal-list-separated();
        font-size: $base-font-size - 1px;
        font-weight: $semi-bold;
        padding-left: 0;

        li {
            list-style-type: none;
            padding: 0px; 
        }

        &.single {
            @media #{$above768} {
                @include flex;
                justify-content: space-between;
                flex-wrap: wrap;

                li {
                    width: 31.5%;
                }
            }

            &.two-column {
                li {
                    @media #{$above768} {
                        width: 48.5%;
                    }
                }
            }
        }

        a {
            padding: 0 10px;
        }
    }
}