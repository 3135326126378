﻿/* _carousel.scss -- */
//@import "../base/colors";
//@import "../base/typography";
@import "../helpers/mixins";

.fwc-carousel.carousel {
    height: 480px;
    font-family: $base-font-family;
    overflow: hidden;

    @media #{$below768} {
        height: auto;
    }

    .carousel-control-position {
        position: absolute;
        width: 100%;
        bottom: 23px;

        &.video-playing {
            display: none;
        }

        .controls-wrapper {
            position: relative;
            height: 40px;
            z-index: 1;
            background-color: $fwc-light-tan;

            @media #{$below768} {
                background-color: transparent;
            }
        }

        @media #{$below768} {
            background-color: $fwc-gray-dark;
            padding: 10px 0;
            position: relative;
            bottom: 0;
        }
    }

    .carousel-content-position {
        position: absolute;
        width: 100%;
        bottom: 23px;
        z-index: 1;
        .col-md-6 {
            height:100%;
        }
        @media #{$below768} {
            /*position: relative;*/
            bottom: 0;
            height:160px;
        }

        > .container, .row {
            height: 100%;

            @media #{$below768} {
                padding: 0;
            }
        }
    }

    ol.carousel-indicators {
        margin: 0;
        padding: 0;
        overflow: visible;
        bottom: 8px;
        z-index: 1;

        @media #{$below768} {
            position: absolute;
            margin: 0;
            bottom: 8px;
        }

        li {
            height: auto;
            width: auto;
            padding: 0;
            list-style-type: none;
            text-indent: 0;
            text-align: center;
            background-color: transparent;

            @media #{$below768} {
                margin-right: 6px;
                margin-left: 6px;
            }

            a {
                height: 25px;
                width: 25px;
                display: block;
                background-color: $fwc-not-quite-black;
                border: 1px solid $fwc-not-quite-black;
                color: white;
                border-radius: 54px;
                font-size: 16px;
                line-height: 1.3;

                @media #{$below768} {
                    background-color: white;
                    color: $fwc-not-quite-black;
                }
            }

            &:hover {
                cursor: pointer;
            }

            &.active a {
                cursor: pointer;
                background-color: $fwc-red;
                color: white;
            }
        }
    }

    .play-controls {
        bottom: 7px;
        height: 28px;
        left: 20px;
        position: absolute;
        width: 60px;
        z-index: 2;
        @include flex;
        justify-content: center;

        @media #{$below768} {
            bottom: 7px;
            left: 0;
        }

        a {
            color: $fwc-not-quite-black;
            position: absolute;
            left: 50%;
            margin-left: -19px;

            &.play-button {
                display: none;
            }

            @media #{$below768} {
                margin-left: 0;
                color: white;
            }
        }

        .svg-inline--fa {
            margin: 0 10px;
        }

        .pause-note {
            display: none;
            position: absolute;
            left: 37px;
            font-style: italic;
            font-size: 14px;
            font-weight: $bold;
        }
    }

    .carousel-image {
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center top;

        .container, .row {
            height: 100%;
        }

        @media #{$below768} {
            position: relative;
            height: 100%;
            background-position: 50%;
        }

        &.anchor-left {
            background-position: left center;
        }

        &.anchor-right {
            background-position: right center;
        }
    }

    a.carousel-image-link {
        display: block;
        height: 100%;
        width: 100%;
    }

    .carousel-inner {
        height: 100%;

        @media #{$below768} {
            overflow: visible;
            height:400px;
        }

        .carousel-item {
            height: 100%;
            overflow: hidden;
            @media #{$below768} {
                padding-bottom:160px;
            }
            h2 a {
                color:$fwc-blue;
            }
            h2 a:hover {
                color:$fwc-not-quite-black;
            }
            &.video {
                /*position: relative;*/
                .carousel-image {
                    @include transition($secs:1s);

                    &:before {
                        display: block;
                        content: "";
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-image: url(/images/play-icon.png);
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-color: rgba(0,0,0,.5);
                    }
                }

                &.playing {
                    .carousel-image {
                        background-image: none !important;
                        background-color: black;
                    }

                    .carousel-content-position {
                        display: none;
                    }
                }

                .video-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    /*background-image: url(/images/play-icon.png);
                    background-repeat: no-repeat;
                    background-position: center center;*/
                    z-index: 1;
                }

                .video-placeholder {
                    display: block;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    opacity: 1;
                    cursor: pointer;
                    @include transition;
                }

                .video-item {
                    width: 100%;
                    height: 100%;
                    @include transition;

                    &.active {
                        .video-placeholder {
                            opacity: 0;
                            z-index: 0;
                        }

                        .video-container {
                            z-index: 1;
                            opacity: 1;
                        }
                    }
                }

                .video-container {
                    display: block;
                    position: absolute;
                    z-index: 0;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    @include transition;

                    iframe {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100% !important;
                        height: 100% !important;
                    }

                    &.active {
                        opacity: 1;
                    }
                }
            }
        }
    }

    @include shaded-strips;

    &:after {
        @media #{$below768} {
            display: none;
        }
    }

    &.is-paused {
        .play-controls {
            .pause-button {
                display: none;
            }

            .play-button {
                display: block;
            }

            .pause-note {
                display: block;
            }
        }
    }
    /*&:hover {
        .pause-note {
            display:block;
        }
    }*/
    &.single-slide {
        .carousel-arrows-position {
            display: none;
        }
    }

    .carousel-arrows-position {
        @include vertical-align(absolute);
        width: 100%;

        &.video-playing {
            display: none;
        }

        a.carousel-control-prev, a.carousel-control-next {
            opacity: 1;
            width: 70px;
            height: 77px;
            background-color: rgba(255,255,255,.3);
            @include vertical-align(absolute);

            span.carousel-control-prev-icon, span.carousel-control-next-icon {
                width: 30px;
                height: 30px;
            }

            @media #{$below768} {
                transform: translateY(-88%);
                -webkit-transform: translateY(-88%);
            }
        }

        a.carousel-control-next {
            right: 15px;
        }

        a.carousel-control-prev {
            left: 15px;
        }
    }
}
.carousel-content {
    position:relative;
    height:100%;
    padding:10px 10px 40px;
    text-align:center;
    background-color:$fwc-light-tan;
    h1, h2 {
        font-size:26px;
        text-align:center;
        font-weight:$semi-bold;
        color:white;
        margin:0;
        padding:5px 0;
        max-height:80px;
        overflow:hidden;
        color:$fwc-red;
        @media #{$between768and992} {
            font-size:28px;
        }
        @media #{$below768} {
            max-height:initial;
        }
    }
    p {
        margin:0 0 5px;
        max-height:119px;
        overflow:hidden;
        font-weight:$medium;
        font-size:18px;
        @media #{$below768} {
            max-height:initial;
        }
        @media #{$below480} {
            font-size:14px;
        }
    }
    a.fwc-button {
	    max-height:80px;
	    overflow:hidden;
	    @media #{$between768and1200} {
		    font-size:17px;
		    padding: 12px 40px;
	    }
    }
    .vert-pos {
        @media #{$below768} {
            width:100%;
            @include vertical-align(absolute);
        }
    }
    @media #{$below768} {
        padding:10px;
    }
    @include link-icons();
}